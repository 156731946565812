.Changer {
    max-width: 1360px;
    height: auto;
    display: block;
}

.Changer h2 {
    color: white;
    font-size: 50px;
    text-align: center;
    font-weight: normal;
    padding: 45px 100px 45px 100px;
}

