.Footer_Content a {
    display: inline-block;
    vertical-align: top;
    color: white;
    font-size: 30px;
    text-decoration: none;
}

.Footer_Content img {
    padding-right: 7px;
}