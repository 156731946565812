.Changer_Content {
    max-width: 1360px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Changer_Img_Link {
    width: 250px;
    height: 60px;
    display: flex;
    margin: 45px auto 45px auto;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: #D9D9D9;
}

.Changer_Img_Link {
    color: black;
    font-size: 30px;
    text-transform: uppercase;
    text-decoration: none;
    user-select: none;
}

.Changer_Img_Link:hover {
    color: white;
    background-color: RGB(0, 0, 0, 0.3);
    outline: 1px solid white;
    transition: 0.5s;
}

.fixer2:last-child img {
    max-width: 302px;
    height: auto;
    padding-top: 66px;
    padding-bottom: 66px;
}
