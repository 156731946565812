.Pseudo_Table {
    display: block;
    border-bottom: 1px solid white;
    border-radius: 6px;
    padding-bottom: 4px;
    margin-bottom: 30px;
    text-transform: uppercase;
}

.Pseudo_Table p {
    font-size: 30px;
    color: white;
}

.Pseudo_Col {
    width: 500px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
}