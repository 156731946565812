.Sales {
    padding-bottom: 140px;
}

.NDS {
    max-width: 1000px;
    display: block;
    margin: auto;
    padding: 0 20px 0 20px;
    color: white;
}

.NDS h2 {
    text-align: center;
    padding-bottom: 20px;
}

.NDS p {
    text-align: justify;
}

.NDS h2, .NDS p {
    font-size: 30px;
}