.Table_Container {
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: flex;
    padding: 160px 0 30px 0;
    align-items: center;
    justify-content: center;
}

.GoBack {
    width: 250px;
    height: 60px;
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    user-select: none;
    border-radius: 6px;
    text-decoration: none;
    color: black;
    font-size: 30px;
    text-transform: uppercase;
    background-color: #D9D9D9;
}

.GoBack:hover {
    color: white;
    background-color: RGB(0, 0, 0, 0.3);
    outline: 1px solid white;
    transition: 0.5s;
}