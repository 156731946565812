header {
    width: 100%;
    height: 120px;
    display: block;
    position: fixed;
    background-color: RGB(0, 0, 0, 0.3);
}

main {
    background-image: url('../../images/background.png');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
}

footer {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
}

.Header_Container {
    width: 100%;
    height: 100%;
    position: relative;
}

header img {
    max-width: 370px;
    position: absolute;
    left: 85px;
    top: calc(50% - 48px);
    user-select: none;
}

.Call {
    width: 250px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 30px;
    top: calc(50% - 30px);
    user-select: none;
    border-radius: 6px;
    background-color: RGB(0, 0, 0, 0.3);
    outline: 1px solid white;
}

.Call p {
    color: white;
    font-size: 30px;
    text-transform: uppercase;
    text-decoration: none;
}

.Call:hover {
    background-color: #D9D9D9;
    transition: 0.5s;
}

.Call:hover p {
    color: black;
    transition: 0.5s;
}

.Footer_Content {
    padding-right: 47px;
}

.Footer_Content:last-child {
    padding-right: 0;
}

.Fixer {
    background-color: RGB(0, 0, 0, 0.9);
}
