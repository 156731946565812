@media screen and (max-height: 860px) {
    .Main {
        width: 100%;
        display: block;
        height: auto;
        padding: 178px 0 178px 0;
    }

    .Changer {
        width: 100%;
        margin: auto;
    }
}

@media screen and (max-width: 1450px) {

    header {
        background-color: unset;
        position: static;
    }

    .Table_Container {
        padding-top: 40px;
    }

    .Sales {
        padding-bottom: 40px;
    }

    .Main {
        width: 100%;
        height: auto;
        display: block;
        padding: 0 0 0 0;
    }

    footer {
        background-color: unset;
        position: static;
    }

    .Changer {
        height: auto;
        margin: auto;
        background-color: unset;
    }

    .Changer_Content {
        display: block;
        height: auto;
    }

    .Changer_Content_Img {
        display: block;
        height: auto;
        margin: auto;
        padding: 0;
    }

    .Footer_Content a {
        padding-top: 6px;
        font-size: 24px;
    }

    .fixer2:last-child img {
        padding-top: 0;
        padding-bottom: 0;
    }


}

@media screen and (max-width: 1230px) {

    footer {
        display: block;
        height: auto;
    }

    .Footer_Content {
        width: auto;
        height: auto;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 20px 20px 20px;
    }

    .Footer_Content:last-child {
        width: auto;
        height: auto;
        margin: auto;
        padding: 20px 20px 20px 20px;
    }

    .Footer_Content img {
        display: inline-block;
        vertical-align: top;
    }

    .Footer_Content a {
        display: inline-block;
        vertical-align: top;
        padding-top: 6px;
        font-size: 24px;
    }
}

@media screen and (max-width: 1000px) {

    .Table_Container {
        min-height: 0;
        padding: 10px 0 40px 0;
    }

    .Pseudo_Col {
        max-width: 390px;
    }

}

@media screen and (max-width: 804px) {

    .Pseudo_Col {
        max-width: 300px;
    }

    .Pseudo_Table p {
        font-size: 24px;
        color: white;
    }

    header {
        height: auto;
    }

    .Header_Container {
        height: auto;
    }

    header img {
        padding-top: 20px;
        margin: 0 auto 20px auto;
        display: block;
        position: static;
    }

    .Call {
        margin: 40px auto 40px auto;
        position: static;
    }

    header a {
        text-decoration: none;
    }

    .Changer h2 {
        font-size: 36px;
    }

}

@media screen and (max-width: 635px) {

    .Pseudo_Col {
        max-width: 250px;
    }

    .Pseudo_Col p {
        font-size: 20px;
    }

    .Changer h2 {
        font-size: 32px;
    }

    .NDS h2, .NDS p {
        font-size: 24px;
    }

}

@media screen and (max-width: 525px) {

    .Pseudo_Col {
        max-width: 200px;
    }

    .Changer h2 {
        display: none;
    }

}

@media screen and (max-width: 402px) {

    .Pseudo_Col {
        max-width: 170px;
    }

    .Call {
        padding-right: 0;
        padding-left: 0;
    }

    .Changer_Content_Img {
        max-width: 200px;
    }

    header img {
        max-width: 240px;
    }

    .Footer_Content a {
        font-size: 18px;
    }

}

@media screen and (max-width: 340px) {

    .Pseudo_Col {
        width: auto;
    }

    .Pseudo_Table .Pseudo_Col:last-child {
        padding-right: 20px;
        text-align: center;
        float: right;
    }

    .Pseudo_Table p {
        font-size: 14px;
    }
}

@media screen and (max-width: 310px) {

    .Footer_Content a {
        font-size: 14px;
    }

    .NDS h2, .NDS p {
        font-size: 18px;
    }

}